.hero-section {
  background-image: url("../public/images/pexels.jpg"); /* The image used */
  background-color: #000; /* Used if the image is unavailable */
  min-height: 100vh;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.hero-section h1 {
  color: #fff;
  font-size: 60px;
}

.me-image {
  vertical-align: middle;
  width: 150px;
  border-radius: 50%;
}

.avatar-reference {
  vertical-align: middle;
  width: 100px;
  border-radius: 50%;
}

.contact {
  min-height: 100vh;
}

.skill-item {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fef4f5;
}

.skill-item img {
  width: 50%;
  height: 50%;
}

.skill-item:hover {
  box-shadow: 0 0 25px #fef4f5;
}

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -0.05rem;
}

@media only screen and (min-width: 1000px) {
  .about-section {
    padding: 50px;
  }

  .me-image {
    vertical-align: middle;
    width: 200px;
    border-radius: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .me-image {
    vertical-align: middle;
    width: 250px;
    border-radius: 50%;
  }
}
